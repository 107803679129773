@tailwind base;
@tailwind components;
@tailwind utilities;


* {
    font-family: "Poppins", serif;
    font-style: normal;
}

.bg-primary {
    background: #15112B !important;
}

.bg-secondary {
    background: #D1B657 !important;
}

.text-primary {
    color: #15112B !important;
}

.text-secondary {
    color: #D1B657 !important;
}

.parallax-table {
    background-color: #E8E9EF;
    color: #15112B;
    height: 100%;
}
.full-screen-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgb(245 245 245 / 80%);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loader-wrap {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    overflow: hidden;
}
.loader-circles {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    transform: rotate(45deg);
    width: 100px;
    height: 100px;
}
.loader-circles .circle {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 50%;
    border-bottom-color: transparent;
    border-right-color: transparent;
    text-align: center;
}
.loader-circles .circle:nth-child(even) {
    border-color: #15112B;
    border-bottom-color: transparent;
    border-right-color: transparent;
}
.loader-circles .circle:nth-child(odd) {
    border-color: #B5972D;
    border-bottom-color: transparent;
    border-right-color: transparent;
}
.loader-circles .circle:nth-child(1) {
    width: 10px;
    height: 10px;
    animation: rotate-circle linear infinite;
    animation-duration: 12s;
}
.loader-circles .circle:nth-child(2) {
    width: 20px;
    height: 20px;
    animation: rotate-circle linear infinite;
    animation-duration: 6s;
}
.loader-circles .circle:nth-child(3) {
    width: 30px;
    height: 30px;
    animation: rotate-circle linear infinite;
    animation-duration: 4s;
}
.loader-circles .circle:nth-child(4) {
    width: 40px;
    height: 40px;
    animation: rotate-circle linear infinite;
    animation-duration: 3s;
}
.loader-circles .circle:nth-child(5) {
    width: 100px;
    height: 100px;
    animation: rotate-circle linear infinite;
    animation-duration: 2.4s;
}
.loader-circles .circle:nth-child(6) {
    width: 60px;
    height: 60px;
    animation: rotate-circle linear infinite;
    animation-duration: 2s;
}
.loader-circles .circle:nth-child(7) {
    width: 70px;
    height: 70px;
    animation: rotate-circle linear infinite;
    animation-duration: 1.71429s;
}
.loader-circles .circle:nth-child(8) {
    width: 80px;
    height: 80px;
    animation: rotate-circle linear infinite;
    animation-duration: 1.5s;
}
.loader-circles .circle:nth-child(9) {
    width: 90px;
    height: 90px;
    animation: rotate-circle linear infinite;
    animation-duration: 1.33333s;
}
.loader-circles .circle:nth-child(10) {
    width: 50px;
    height: 50px;
    animation: rotate-circle linear infinite;
    animation-duration: 1.2s;
}
@keyframes rotate-circle {
    100% {
        transform: rotate(360deg);
    }
}
