.home-wrapper {
    position: absolute;
    background-color: ghostwhite !important;
    background-image: url("/public/images/pages/home/bg_main.svg");
    width: 100%;
    height: 100vh;
    background-size: cover;
}
.outlet-wrapper {
    padding: 1rem 2rem;
}
