.dropzone {
    flex: 1;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    margin-bottom: 10px;
}
.file-icon {
    color: red;
    cursor: pointer;
}
.file-icon:hover {
    color: #802121;
    transition: all 1s;
}
.file-wrapper {
    padding: 10px;
    cursor: pointer;
    margin-bottom: 5px;
    box-shadow: 0 0 1px gray;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: whitesmoke;
}

.file-wrapper:hover {
    background-color: #e5e5e5;
}
